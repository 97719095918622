/** @module application 薬キャリエージェントサイトで共通利用するスクリプト */
import '@/javascripts/ui/smartRollover';
import '@/javascripts/ui/header_fixed';
import '@/javascripts/ui/header_menu';
import '@/javascripts/ui/pc_sp_img_change';
import '@/javascripts/ui/footer_scroll_action';
import '@/javascripts/ui/pagetop';
import $ from 'jquery';
import { pharmacistFavoriteCount } from '@/javascripts/utils/favorite_positions';

// Sentryの初期化
import { initSentry } from '@/javascripts/utils/sentry_helper';
initSentry();

$(function() {
  pharmacistFavoriteCount();
});
